import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropertyModal from '../modals/PropertyModal';
import VisitorModal from '../modals/VisitorModal';
import UserModal from '../modals/UserModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Ensure the correct import
import Cookies from 'js-cookie';
import debounce from 'lodash/debounce';

// Remove this from the top-level scope
// const token = Cookies.get('token');

const debouncedFetchData = debounce(async (dataType, search, propertyFilter, roleFilter, setData) => {
  const token = Cookies.get('token');  // Moved inside the function
  try {
    const response = await axios.get(`/api/${dataType}/get-all`, {
      params: {
        search,
        property: propertyFilter,
        role: roleFilter,
      },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    setData(response.data);
    // console.log('Data fetched:', response.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}, 500);

const Dashboard = () => {
  const [dataType, setDataType] = useState('resident');
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [propertyFilter, setPropertyFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [properties, setProperties] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');  // Retrieve token each time
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        // console.log('Decoded Token:', decodedToken);
        const role = decodedToken.role || null;
        setUserRole(role);
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserRole(null);
      }
    } else {
      console.log('No token found');
      setUserRole(null);
    }
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      const token = Cookies.get('token');  // Retrieve token before API call
      try {
        const response = await axios.get(`/api/property/get-all`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setProperties(response.data);
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    debouncedFetchData(dataType, search, propertyFilter, roleFilter, setData);
  }, [dataType, search, propertyFilter, roleFilter]);

  useEffect(() => {
    if (roleFilter === 'superadmin') {
      setPropertyFilter('');
    }
  }, [roleFilter]);

  const handleEdit = (item) => {
    setSelectedItem(item);
    if (dataType === 'resident') {
      setIsModalOpen(true);
    } else if (dataType === 'property') {
      setIsPropertyModalOpen(true);
    } else if (dataType === 'visitor') {
      setIsVisitorModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsPropertyModalOpen(false);
    setIsVisitorModalOpen(false);
    setIsConfirmModalOpen(false);
    setSelectedItem(null);
  };

  const handleViewQRCodes = (visitorId) => {
    navigate(`/visitor/${visitorId}/qr-codes`);
  };

  const handleSave = async (updatedItem) => {
    const token = Cookies.get('token');  // Fetch the token just before saving
    try {
      await axios.post(`/api/${dataType}/update`, updatedItem, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setData((prevData) =>
        prevData.map((item) => (item._id === updatedItem._id ? updatedItem : item))
      );
      handleCloseModal();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = async (id) => {
    const token = Cookies.get('token');  // Fetch the token just before deletion
    try {
      await axios.post(`/api/${dataType}/delete`, { _id: id }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setData((prevData) => prevData.filter((item) => item._id !== id));
      handleCloseModal();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const confirmDelete = (item) => {
    setSelectedItem(item);
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="p-4 bg-white border rounded shadow-lg">
      <div className="mb-4 flex items-center space-x-4">
        {isAuthenticated && userRole === 'superadmin' && (
          <select
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            className="p-2 border rounded border-black"
          >
            <option value="resident">Users</option>
            <option value="visitor">Visitors</option>
            <option value="property">Properties</option>
          </select>
        )}

        {/* {isAuthenticated && userRole !== "resident" && (
          <select
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            className="p-2 border rounded border-black"
          >
            {userRole === 'admin' ? (
              // Show only 'Users' and 'Visitors' for admin role
              <>
                <option value="resident">Users</option>
                <option value="visitor">Visitors</option>
              </>
            ) : (
              // Otherwise, show all options for superadmin or other roles
              <>
                <option value="resident">Users</option>
                <option value="visitor">Visitors</option>
                <option value="property">Properties</option>
              </>
            )}
          </select>
        )} */}

        {dataType !== 'property' && (
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by name or email"
            className="p-2 border rounded border-black"
          />
        )}

        {isAuthenticated && userRole === 'superadmin' && dataType !== 'property' && (
          <>
            <select
              value={propertyFilter}
              onChange={(e) => setPropertyFilter(e.target.value)}
              className={`p-2 border rounded border-black ${roleFilter === 'superadmin' ? 'hidden' : ''}`}
            >
              <option value="">Filter by property</option>
              {properties.map((property) => (
                <option key={property._id} value={property._id}>
                  {property.propertyName}
                </option>
              ))}
            </select>
            {dataType === 'resident' && (
              <select
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
                className="p-2 border rounded border-black"
              >
                <option value="">Filter by role</option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Property Manager</option>
                <option value="resident">Resident</option>
              </select>
            )}
          </>
        )}
      </div>

      {/* Render tables based on dataType */}
      {dataType === 'resident' && (
        <table className="w-full border">
          <thead className="border-b border-surface bg-surface-light text-sm font-medium text-foreground dark:bg-surface-dark">
            <tr>
              <th className="table-fixed text-center border border-black">First Name</th>
              <th className="table-fixed text-center border border-black">Last Name</th>
              <th className="table-fixed text-center border border-black">Email</th>
              {/* <th className="table-fixed text-center border border-black">Phone Number</th> */}
              <th className="table-fixed text-center border border-black">Role</th>
              <th className="table-fixed text-center border border-black">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((user) => (
              <tr key={user._id}>
                <td className="table-fixed text-center border border-black">{user.firstName}</td>
                <td className="table-fixed text-center border border-black">{user.lastName}</td>
                <td className="table-fixed text-center border border-black">{user.email}</td>
                {/* <td className="table-fixed text-center border border-black">{user.phoneNumber}</td> */}
                <td className="table-fixed text-center border border-black">{user.role === 'superadmin' ? 'Super User' : user.role === 'admin' ? 'Property Manager' : 'Resident'}</td>
                <td className="table-fixed text-center border border-black">
                  <button
                    onClick={() => handleEdit(user)}
                    className="bg-blue-500 text-white p-2 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => confirmDelete(user)}
                    className="bg-red-500 text-white p-2 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {dataType === 'visitor' && (
        <table className="w-full">
          <thead className="border-b border-surface bg-surface-light text-sm font-medium text-foreground dark:bg-surface-dark">
            <tr>
              <th className="table-fixed text-center border border-black">First Name</th>
              <th className="table-fixed text-center border border-black">Last Name</th>
              <th className="table-fixed text-center border border-black">Email</th>
              <th className="table-fixed text-center border border-black">QR Codes</th>
              <th className="table-fixed text-center border border-black">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((visitor) => (
              <tr key={visitor._id}>
                <td className="table-fixed text-center border border-black">{visitor.firstName}</td>
                <td className="table-fixed text-center border border-black">{visitor.lastName}</td>
                <td className="table-fixed text-center border border-black">{visitor.email}</td>
                <td className="table-fixed text-center border border-black">
                  <button className='bg-blue-500 text-white p-2 rounded mr-2' onClick={() => handleViewQRCodes(visitor._id)}>
                    View QR Codes
                  </button>
                </td>
                <td className="table-fixed text-center border border-black">
                  <button
                    onClick={() => handleEdit(visitor)}
                    className="bg-blue-500 text-white p-2 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => confirmDelete(visitor)}
                    className="bg-red-500 text-white p-2 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {dataType === 'property' && (
        <table className="w-full">
          <thead className="border-b border-surface bg-surface-light text-sm font-medium text-foreground dark:bg-surface-dark">
            <tr>
              <th className="table-fixed text-center border border-black">Name</th>
              <th className="table-fixed text-center border border-black">Location</th>
              <th className="table-fixed text-center border border-black">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((property) => (
              <tr key={property._id}>
                <td className="table-fixed text-center border border-black">{property.propertyName}</td>
                <td className="table-fixed text-center border border-black">{property.propertyLocation}</td>
                <td className="table-fixed text-center border border-black">
                  <button
                    onClick={() => handleEdit(property)}
                    className="bg-blue-500 text-white p-2 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => confirmDelete(property)}
                    className="bg-red-500 text-white p-2 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {isModalOpen && <UserModal item={selectedItem} onSave={handleSave} onClose={handleCloseModal} />}
      {isPropertyModalOpen && <PropertyModal item={selectedItem} onSave={handleSave} onClose={handleCloseModal} />}
      {isVisitorModalOpen && <VisitorModal item={selectedItem} onSave={handleSave} onClose={handleCloseModal} />}
      {isConfirmModalOpen && <ConfirmationModal isOpen={isConfirmModalOpen} onClose={handleCloseModal} onConfirm={handleDelete} item={selectedItem} type={dataType} />}
    </div>
  );
};

export default Dashboard;
