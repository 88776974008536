import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const PasswordResetPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordErrors, setPasswordErrors] = useState({
    minLength: false,
  });

  const location = useLocation();
  // const navigate = useNavigate();
  const { login } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const _id = queryParams.get('_id');

  const validatePassword = (password) => {
    setPasswordErrors({
      minLength: password.length >= 6,
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!Object.values(passwordErrors).every(Boolean)) {
      setError('Please meet all password requirements');
      return;
    }

    try {
      const response = await axios.post(`/api/resident/verify-email`, { _id, newPassword: password });
      setSuccess(response.data.message);
      login(response.data.token);
    } catch (err) {
      setError(err.response?.data?.message || 'Error verifying email');
      setSuccess('');
    }
  };

  return (
    <div className="max-w-lg mx-auto border bg-slate-100 p-4 rounded">
      <h2 className="text-2xl font-bold mb-4">Verify Email and Set New Password</h2>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <label htmlFor="password" className="block text-sm font-medium mb-1">New Password:</label>
          <input
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            required
            className="w-full border border-gray-300 p-2 rounded"
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-3 py-2 text-blue-600 focus:outline-none"
            onClick={() => setShowPassword(!showPassword)}
            style={{ visibility: 'visible' }} // Ensure visibility
          >
            {/* Optional: Add an icon for better UX */}
            {showPassword ? 'Hide' : 'Show'}
          </button>
          <div className="mt-2 text-sm text-gray-600">
            <p className={`text-${passwordErrors.minLength ? 'green' : 'red'}-500`}>
              {password.length >= 6 ? '✓ Minimum 6 characters' : '✗ Minimum 6 characters'}
            </p>
          </div>
        </div>
        <div className="relative">
          <label htmlFor="confirmPassword" className="block text-sm font-medium mb-1">Confirm New Password:</label>
          <input
            id="confirmPassword"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            className="w-full border border-gray-300 p-2 rounded"
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-3 mt-5 py-2 text-blue-600 focus:outline-none"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            style={{ visibility: 'visible' }} // Ensure visibility
          >
            {/* Optional: Add an icon for better UX */}
            {showConfirmPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Set Password</button>
      </form>
    </div>
  );
};

export default PasswordResetPage;
