import React from 'react';
import { Link } from 'react-router-dom';
import error from '../assets/images/Error.png'; // Import the logo image

const ErrorPage = () => {
  return (
    <div className="flex items-start justify-center min-h-screen">
      <div className="text-center bg-white px-8">
        <h1 className="text-6xl font-bold text-red-600">404</h1>
        <p className="text-2xl font-medium text-gray-700 mt-4">
          Oops! The page you're looking for does not exist.
        </p>
        <p className="text-gray-500 mt-2">
          It might have been moved or deleted.
        </p>
        <div className="mt-6">
          <Link to="/login/Resident-Login" className="text-blue-500 hover:underline">
            Go back to Home
          </Link>
        </div>
        <div className="mt-8">
          <img
            src={error}
            alt="Lost in Space"
            className="w-96 h-96 mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
