import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { isAuthenticated, isLoading, role } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Loading state
  }

  // Determine navigation page based on user role
  let navPage = '/login';

  if (role === 'superadmin') navPage += '/Super-Admin';
  else if (role === 'admin') navPage += '/Property-Manager-Login';
  else if (role === 'resident') navPage += '/Resident-Login';
  else navPage += '/Resident-Login'; // Default to login page if no role is found

  // If not authenticated, redirect to the determined login page
  if (!isAuthenticated) {
    return <Navigate to={navPage} replace />;
  }

  // Check if the user's role is allowed to access this route
  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/403" replace />; // Redirect to 403 Forbidden page
  }

  return children; // Render children if the user is authorized
};

export default ProtectedRoute;
