import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
// import LogoutPage from './login/Logout';
import RegisterVisitorPage from './pages/register/RegisterVisitorPage';
// import ValidatePage from './pages/ValidatePage';
import RegisterUserPage from './pages/register/RegisterUserPage';
import RegisterPropertyPage from './pages/register/RegisterPropertyPage';
import PasswordResetPage from './pages/PasswordResetPage';
import DashboardPage from './pages/dashboards/DashboardPage';
import VisitorsListPage from './pages/dashboards/VisitorsListPage';
import VisitorQRCodesPage from './pages/VisitorQRCodesPage';
import BulkUserUploadPage from './pages/dashboards/BulkUserUploadPage';
import ErrorPage from './pages/ErrorPage'; // Import the ErrorPage component

function App() {

  return (
    <Router>
      <AuthProvider>
        <div className="flex flex-col min-h-screen container mx-auto">
          <Header />
          <main className="flex-grow min-h-[60vh] mb-8">
            <div className="container mx-auto p-4">
              <Routes>
                <Route path="/login/Resident-Login" element={<LoginPage />} />
                <Route path="/login/Property-Manager-Login" element={<LoginPage />} />
                <Route path="/login/Super-Admin" element={<LoginPage />} />

                {/* Public Routes */}
                <Route path="/verify-email" element={<PasswordResetPage />} />

                {/* Protected Routes */}
                <Route path="/visitor/:visitorId/qr-codes" element={<ProtectedRoute allowedRoles={['superadmin']}><VisitorQRCodesPage /></ProtectedRoute>} />

                {/* Role-based protected routes */}
                <Route path="/" element={<ProtectedRoute allowedRoles={['resident', 'superadmin', 'admin']}><RegisterVisitorPage /></ProtectedRoute>} />
                <Route path="/register-user" element={<ProtectedRoute allowedRoles={['superadmin', 'admin']}><RegisterUserPage /></ProtectedRoute>} />
                <Route path="/register-property" element={<ProtectedRoute allowedRoles={['superadmin']}><RegisterPropertyPage /></ProtectedRoute>} />
                <Route path="/dashboard" element={<ProtectedRoute allowedRoles={['superadmin', 'admin']}><DashboardPage /></ProtectedRoute>} />
                <Route path="/visitor-list" element={<ProtectedRoute allowedRoles={['resident']}><VisitorsListPage /></ProtectedRoute>} />
                <Route path="/bulk-user-upload" element={<ProtectedRoute allowedRoles={['superadmin','admin']}><BulkUserUploadPage /></ProtectedRoute>} />

                {/* Catch-all route for 404 errors */}
                <Route path="*" element={<ErrorPage />} />
                <Route path="/403" element={<ErrorPage code={403} />} /> {/* Optional 403 page */}

                {/* <Route path="/logout" element={<LogoutPage />} /> */}
              </Routes>
            </div>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
