// src/pages/DashboardPage.js
import React, { useState, useEffect } from 'react';
import Dashboard from '../../components/dashboards/Dashboard';
import { jwtDecode } from 'jwt-decode'; // Ensure the correct import
import { Navigate } from 'react-router-dom'; // Import Navigate for redirection
import Cookies from 'js-cookie'; // Import js-cookie


const DashboardPage = () => {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = Cookies.get('token'); // Retrieve token from cookies
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        // console.log('Decoded Token:', decodedToken); // Log the decoded token
        const role = decodedToken.role || null; // Safely extract role
        setUserRole(role);
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserRole(null);
      }
    } else {
      console.log('No token found');
      setUserRole(null);
    }
  }, []);

  if (userRole === null) {
    // Handle cases where role is not found
    return <p>Loading...</p>;
  }

  if (userRole !== 'admin' && userRole !== 'superadmin') {
    // console.log('User role is not admin or superadmin:', userRole);
    // Redirect to home page if the role is not admin or superadmin
    return <Navigate to="/" replace />;
  }
  return (
    <div className="container mx-auto mt-2">
      <Dashboard />
    </div>
  );
};

export default DashboardPage;
