import React from 'react';
import PropTypes from 'prop-types';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, item, type }) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(item._id);
    onClose();
  };

  // Format the confirmation message based on the item type
  const getConfirmationMessage = (item, type) => {
    switch (type) {
      case 'visitor':
        return (
          <div>
            <p>Are you sure you want to delete the visitor?</p>
            <p><b>Name:</b> {item.firstName} {item.lastName}</p>
            <p><b>Email:</b> {item.email}</p>
          </div>
        );
      case 'user':
        return (
          <div>
            <p>Are you sure you want to delete the user?</p>
            <p><b>Name:</b> {item.firstName} {item.lastName}</p>
            <p><b>Email:</b> {item.email}</p>
            <p><b>Role:</b> {item.role}</p>
          </div>
        );
      case 'property':
        return (
          <div>
            <p>Are you sure you want to delete the property {item.propertyName} located at {item.propertyLocation}?</p>
          </div>
        );
      default:
        return <p>Are you sure you want to delete this item?</p>;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded shadow-lg max-w-sm w-full">
        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
        {getConfirmationMessage(item, type)}
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default ConfirmationModal;
