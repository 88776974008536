import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
};

const VisitorsList = () => {
    const [visitors, setVisitors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editedQrCode, setEditedQrCode] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const token = Cookies.get('token');

    useEffect(() => {
        const fetchVisitors = async () => {
            try {
                const response = await axios.get(`/api/visitor/visitors-qr`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setVisitors(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch visitors');
                setLoading(false);
            }
        };

        fetchVisitors();
    }, [token]);

    const validateDates = useCallback(() => {
        if (!editedQrCode) return;

        const { startDate, endDate } = editedQrCode;

        if (startDate && endDate) {
            if (startDate >= endDate) {
                setValidationError('End date must be after start date.');
            } else {
                setValidationError('');
            }
        } else {
            setValidationError('');
        }
    }, [editedQrCode]);

    useEffect(() => {
        validateDates();
    }, [editedQrCode, validateDates]);

    const handleEditQrCode = (visitorId, qrCode) => {
        setEditedQrCode({
            visitorId,
            _id: qrCode._id,
            startDate: new Date(qrCode.startDate),
            endDate: qrCode.lifetimeValidity ? null : new Date(qrCode.endDate),
            lifetimeValidity: qrCode.lifetimeValidity,
        });
        setValidationError('');
    };

    const handleDateChange = (field, date) => {
        setEditedQrCode(prevState => ({
            ...prevState,
            [field]: date
        }));
    };

    const handleLifetimeValidityChange = (checked) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0); // Set to 12 AM

        setEditedQrCode(prevState => ({
            ...prevState,
            lifetimeValidity: checked,
            startDate: checked ? prevState.startDate : new Date(today.setHours(0, 0, 0, 0)), // Set start date to today at 12 AM
            endDate: checked ? null : tomorrow // Set end date to tomorrow at 12 AM
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validationError) return;

        try {
            const startDateUTC = editedQrCode.startDate.toISOString();
            const endDateUTC = editedQrCode.lifetimeValidity
                ? new Date(editedQrCode.startDate).setFullYear(new Date().getFullYear() + 100)
                : editedQrCode.endDate.toISOString();

            await axios.put(`/api/visitor/visitors-qr/${editedQrCode.visitorId}/update-qrcodes`, {
                _id: editedQrCode._id,
                startDate: startDateUTC,
                endDate: endDateUTC,
                lifetimeValidity: editedQrCode.lifetimeValidity,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const response = await axios.get(`/api/visitor/visitors-qr`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setVisitors(response.data);
            setEditedQrCode(null);
            setSuccessMessage('QR code updated successfully.');
        } catch (err) {
            setError(`Failed to update QR code: ${err.response ? err.response.data.message : err.message}`);
        }
    };

    if (loading) return <div className="text-center text-gray-700">Loading...</div>;
    if (error) return <div className="text-center text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-4xl font-bold mb-8 text-gray-800">Visitor List</h1>
            {visitors.length === 0 ? (
                <p className="text-center text-gray-600">No visitors found</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {visitors.map((visitor) => (
                        <div key={visitor._id} className="bg-white shadow-md rounded-lg p-6 border border-gray-200">
                            <p className="text-xl font-semibold text-gray-700">Email: {visitor.email}</p>
                            <div className="mt-6">
                                <h3 className="text-lg font-medium text-gray-800">QR Code Valid Times:</h3>
                                {visitor.qrCodes.map((qr) => (
                                    <div key={qr._id} className="mt-4 border-t border-gray-200 pt-4">
                                        <h2 className="text-xl font-semibold text-gray-800">{qr.qrFirstName} {qr.qrLastName}</h2>
                                        <p className="text-gray-600">Start Date: {formatDate(qr.startDate)}</p>
                                        <p className="text-gray-600">End Date: {qr.lifetimeValidity ? 'Permanent Visitor' : formatDate(qr.endDate)}</p>
                                        <p className="text-gray-600">Created At: {formatDate(qr.createdAt)}</p>
                                        <button
                                            onClick={() => handleEditQrCode(visitor._id, qr)}
                                            className="mt-3 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200">
                                            Edit Visitor Time
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {editedQrCode && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-60">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg">
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Visitor Time</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-medium mb-2">Start Date & Time</label>
                                <DatePicker
                                    selected={editedQrCode.startDate}
                                    onChange={(date) => handleDateChange('startDate', date)}
                                    showTimeInput
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            {!editedQrCode.lifetimeValidity && (
                                <div className="mb-6">
                                    <label className="block text-gray-700 text-sm font-medium mb-2">End Date & Time</label>
                                    <DatePicker
                                        selected={editedQrCode.endDate}
                                        onChange={(date) => handleDateChange('endDate', date)}
                                        showTimeInput
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        timeInputLabel="Time:"
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                            )}
                            <div className="flex items-center mb-6">
                                <input
                                    id="lifetimeValidity"
                                    type="checkbox"
                                    name="lifetimeValidity"
                                    checked={editedQrCode.lifetimeValidity}
                                    onChange={() => handleLifetimeValidityChange(!editedQrCode.lifetimeValidity)}
                                    className="mr-3 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                                <label htmlFor="lifetimeValidity" className="text-gray-700 text-sm font-medium">Check for Permanent Visitor</label>
                            </div>
                            {validationError && <p className="text-red-500 mb-4 text-sm">{validationError}</p>}
                            <button
                                type="submit"
                                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-200"
                                disabled={!!validationError}>
                                Save Changes
                            </button>
                            <button
                                type="button"
                                onClick={() => setEditedQrCode(null)}
                                className="ml-4 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200">
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {successMessage && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-60">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold mb-4 text-gray-800">{successMessage}</h2>
                        <button
                            onClick={() => setSuccessMessage('')}
                            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisitorsList;
