import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const UserModal = ({ item, onSave, onClose }) => {
  const [updatedUser, setUpdatedUser] = useState(item);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsSuperAdmin(decodedToken.role === 'superadmin');
      } catch (error) {
        console.error('Error decoding token:', error);
        setIsSuperAdmin(false); // Default to false if there's an error
      }
    } else {
      setIsSuperAdmin(false);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(updatedUser);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Edit User</h2>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Resident ID</label>
            <input
              type="text"
              name="_id"
              value={updatedUser._id}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              readOnly
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">First Name</label>
            <input
              type="text"
              name="firstName"
              value={updatedUser.firstName}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={updatedUser.lastName}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={updatedUser.phoneNumber}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Resident Role</label>
            {isSuperAdmin ? (
              <select
                name="role"
                value={updatedUser.role}
                onChange={handleChange}
                className="border border-gray-300 p-2 w-full"
                required
              >
                <option value="resident">Resident</option>
                <option value="admin">Admin</option>
                <option value="superadmin">Super Admin</option>
              </select>
            ) : (
              <input
                type="text"
                name="role"
                value={updatedUser.role}
                className="border border-gray-300 p-2 w-full"
                readOnly
              />
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white p-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserModal;
