// src/pages/RegisterVisitorPage.js
import React from 'react';
import RegisterVisitor from '../../components/forms/RegisterVisitor';

const RegisterVisitorPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <RegisterVisitor />
    </div>
  );
};

export default RegisterVisitorPage;
