// src/components/PropertyModal.jsx
import React, { useState } from 'react';

const PropertyModal = ({ item, onSave, onClose }) => {
  const [updatedProperty, setUpdatedProperty] = useState(item);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProperty({ ...updatedProperty, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(updatedProperty);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Edit Property</h2>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Property ID</label>
            <input
              type="text"
              name="_id"
              value={updatedProperty._id}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              readOnly
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Property Name</label>
            <input
              type="text"
              name="propertyName"
              value={updatedProperty.propertyName}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Property Location</label>
            <input
              type="text"
              name="propertyLocation"
              value={updatedProperty.propertyLocation}
              onChange={handleChange}
              className="border border-gray-300 p-2 w-full"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white p-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertyModal;
