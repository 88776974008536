// src/pages/RegisterPropertyPage.js
import React from 'react';
import RegisterProperty from '../../components/forms/RegisterProperty';

const RegisterPropertyPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <RegisterProperty />
    </div>
  );
};

export default RegisterPropertyPage;
