// src/pages/VisitorsListPage.js
import React from 'react';
import VisitorsList from '../../components/dashboards/VisitorsList';

const VisitorsListPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <VisitorsList />
    </div>
  );
};

export default VisitorsListPage;
