// src/pages/ErrorPage.js
import React from 'react';
import Error from '../components/Error';

const ErrorPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <Error />
    </div>
  );
};

export default ErrorPage;
