import React from 'react';
import Login from '../login/Login';
import SuperAdminLogin from '../login/SuperAdminLogin';

const LoginPage = () => {
  // Extract the type and propertyName from the URL
  const url = window.location.href;
  const urlParts = url.split('/');
  let type = urlParts[urlParts.length - 1];
  if (type === '' ){
    type = urlParts[urlParts.length - 2];
  }
  // console.log('Type:', type);

  return (
    <div className="container mx-auto mt-2">
      {type === 'Resident-Login' && <Login />}
      {type === 'Property-Manager-Login' && <Login />}
      {type === 'Super-Admin' && <SuperAdminLogin />}
      {(!type || !['Resident-Login', 'Property-Manager-Login', 'Super-Admin'].includes(type)) && (
        <Login />
      )}
    </div>
  );
};

export default LoginPage;
