import React from 'react';
import { CSVLink } from 'react-csv'; // Import CSVLink for exporting CSV

const Modal = ({ isOpen, onClose, message, errorDetails }) => {
  if (!isOpen) return null;

  // Convert error details to CSV format
  const csvHeaders = [
    { label: 'Email', key: 'email' },
    { label: 'Error Message', key: 'message' }
  ];
  
  const csvData = errorDetails.map(error => ({
    email: error.email,
    message: error.message
  }));

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[50vh] flex flex-col">
        <div className="flex-grow overflow-y-auto mb-4">
          <h2 className="text-xl font-bold mb-4">{message}</h2>
          {errorDetails.length > 0 && (
            <>
              <div className="text-red-500 mb-4">
                {errorDetails.length} users failed to upload
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Error Message</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {errorDetails.map((error, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{error.email}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{error.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-between items-center">
          {errorDetails.length > 0 && (
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={'errors.csv'}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Export CSV
            </CSVLink>
          )}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
