// src/pages/VisitorQRCodesPage.js
import React from 'react';
import VisitorQRCodes from '../components/dashboards/VisitorQRCodes';

const VisitorQRCodesPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <VisitorQRCodes />
    </div>
  );
};

export default VisitorQRCodesPage;
