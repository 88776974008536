import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie'; // Import js-cookie
import logo from '../assets/logos/companylogo.png'; // Import the logo image
import axios from 'axios'; // Import axios for HTTP requests

const Headerqr = () => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to handle menu toggle
  const [userRole, setUserRole] = useState(null); // State to store user role
  const [property, setProperty] = useState(null); // State to store property data

  useEffect(() => {
    const token = Cookies.get('token'); // Retrieve token from cookies

    const fetchPropertyData = async (propertyId) => {
      try {
        const response = await axios.get(`/api/property/get-property-by-id`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params: { propertyId }
        });
        setProperty(response.data);
      } catch (error) {
        console.error('Error fetching property:', error);
      }
    };

    // Clear property data when logging out or accessing the email verification page
    if (currentPath === '/verify-email') {
      logout();
      // Optionally reload the page
      // window.location.reload();
    } else {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          setUserRole(decodedToken.role);

          if (decodedToken.PID && decodedToken.role !== 'Super-Admin') {
            fetchPropertyData(decodedToken.PID);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          setUserRole(null);
        }
      } else {
        setUserRole(null); // Ensure role is cleared if no token
        setProperty(null); // Clear property data when no token is found
      }
    }
  }, [isAuthenticated, currentPath, logout]);

  // Function to handle logout and reset the property state
  const handleLogout = () => {
    logout();
    setProperty(null); // Reset the property state when logging out
  };

  // Function to determine if the link is active
  const isActive = (path) => currentPath === path ? 'bg-gray-300' : '';

  return (
    <header className="bg-slate-100 rounded-lg shadow-xl text-black p-4 m-4">
      <nav className="container mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-center relative">
        {/* Logo and Property Info */}
        <div className="flex items-center lg:justify-start w-full">
          <img src={logo} alt="Logo" className="w-20 h-20 mr-4" /> {/* Logo */}
          <div className="text-lg font-bold">
            {property ? (
              <div>
                <div>{property.propertyName}</div>
                <div className="text-sm text-gray-600">{property.propertyLocation}</div>
              </div>
            ) : (
              <div>
                {'Bay Business Edge LLC'}
              </div>
            )}
          </div>
          {/* Mobile Menu Button */}
          {isAuthenticated && (
            <>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="lg:hidden text-black hover:text-gray-500 transition duration-300 ml-auto"
              >
                ☰
              </button>
            </>
          )}
        </div>

        {/* Navigation Links */}
        <ul
          className={`lg:flex lg:space-x-6 space-y-4 lg:space-y-0 w-full lg:w-auto mt-4 lg:mt-0 ${isMenuOpen ? 'block' : 'hidden'} lg:flex lg:items-center bg-gray-200 lg:bg-transparent p-4 lg:p-0`}
        >
          {isAuthenticated && (userRole === 'superadmin' || userRole === 'admin') && (
            <>
              <li className={`rounded-md ${isActive('/dashboard')}`}>
                <Link
                  to="/dashboard"
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Admin Dashboard
                </Link>
              </li>
              <li className={`rounded-md ${isActive('/bulk-user-upload')}`}>
                <Link
                  to="/bulk-user-upload"
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Bulk Registration
                </Link>
              </li>
              {isAuthenticated && (userRole === 'superadmin') && (
                <li className={`rounded-md ${isActive('/register-property')}`}>
                  <Link
                    to="/register-property"
                    className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                  >
                    Register Property
                  </Link>
                </li>
              )}
            </>
          )}
          {(isAuthenticated && userRole !== 'resident') && (
            <li className={`rounded-md ${isActive('/register-user')}`}>
              <Link
                to="/register-user"
                className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
              >
                Register {userRole !== 'superadmin' ? 'Resident' : 'User'}
              </Link>
            </li>
          )}
          {isAuthenticated && (
            <>
              <li className={`rounded-md ${isActive('/')}`}>
                <Link
                  to="/"
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Register Visitor
                </Link>
              </li>
              {userRole === 'resident' && (
                <li className={`rounded-md ${isActive('/visitor-list')}`}>
                  <Link
                    to="/visitor-list"
                    className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                  >
                    Visitor List
                  </Link>
                </li>
              )}
              <li className="rounded-md">
                <button
                  onClick={handleLogout}
                  className="text-sm font-medium text-black hover:text-gray-500 transition duration-300 block text-start lg:text-start p-2"
                >
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Headerqr;
