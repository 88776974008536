import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const token = Cookies.get('token');

const VisitorQRCodesPage = () => {
    const { visitorId } = useParams();
    const [qrCodes, setQrCodes] = useState([]);
    const [editingQRCode, setEditingQRCode] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [lifetimeValidity, setLifetimeValidity] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchQRCodes = async () => {
            try {
                const response = await axios.get(`/api/visitor/${visitorId}/qr-codes`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setQrCodes(response.data);
            } catch (error) {
                console.error('Error fetching QR codes:', error);
            }
        };
        fetchQRCodes();
    }, [visitorId]);

    const formatDate = (date) => {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    };

    const handleEditClick = (qrCode) => {
        let localStartDate = new Date(qrCode.startDate);
        let localEndDate = new Date(qrCode.endDate);

        setEditingQRCode(qrCode);
        setStartDate(localStartDate);
        setEndDate(localEndDate);
        setLifetimeValidity(qrCode.lifetimeValidity || false);
    };

    const setDefaultDates = () => {
        const today = new Date();
        const tomorrow = new Date(today);

        today.setHours(0, 0, 0, 0);
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(today.getDate() + 1);

        setStartDate(today);
        setEndDate(tomorrow);
    };

    const handleLifetimeValidityChange = (checked) => {
        setLifetimeValidity(checked);
        if (!checked) {
            setDefaultDates();
        }
    };

    const handleSaveClick = async () => {
        try {
            const startDateUTC = new Date(startDate).toISOString();
            let endDateUTC;

            if (lifetimeValidity) {
                endDateUTC = new Date(startDateUTC).setFullYear(new Date().getFullYear() + 100);
            } else {
                endDateUTC = new Date(endDate).toISOString();
            }

            await axios.put(`/api/visitor/${editingQRCode._id}/qr-codes`,
                { startDate: startDateUTC, endDate: endDateUTC, lifetimeValidity },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setQrCodes(qrCodes.map(qr =>
                qr._id === editingQRCode._id
                    ? { ...qr, startDate: startDateUTC, endDate: endDateUTC, lifetimeValidity }
                    : qr
            ));
            setEditingQRCode(null);
            setStartDate(null);
            setEndDate(null);
            setLifetimeValidity(false);
            setSuccessMessage('QR code updated successfully!');

            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {
            console.error('Error updating QR code:', error);
        }
    };

    return (
        <div className="p-4 bg-white border rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">QR Codes for Visitor</h2>

            {successMessage && (
                <div className="p-2 mb-4 bg-green-200 text-green-800 border border-green-400 rounded">
                    {successMessage}
                </div>
            )}

            <table className="w-full border">
                <thead className="border-b bg-gray-200 text-sm font-medium">
                    <tr>
                        <th className="border px-4 py-2">First Name</th>
                        <th className="border px-4 py-2">Last Name</th>
                        <th className="border px-4 py-2">Start Date</th>
                        <th className="border px-4 py-2">End Date</th>
                        <th className="border px-4 py-2">Location</th>
                        <th className="border px-4 py-2">Created At</th>
                        <th className="border px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {qrCodes.map(qr => (
                        <tr key={qr._id}>
                            <td className="border px-4 py-2">{qr.qrFirstName}</td>
                            <td className="border px-4 py-2">{qr.qrLastName}</td>
                            <td className="border px-4 py-2">{formatDate(qr.startDate)}</td>
                            <td className="border px-4 py-2">
                                {qr.lifetimeValidity ? 'Permanent Visitor' : formatDate(qr.endDate)}
                            </td>
                            <td className="border px-4 py-2">{qr.propertyName}</td>
                            <td className="border px-4 py-2">{formatDate(qr.createdAt)}</td>
                            <td className="border px-4 py-2">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleEditClick(qr)}
                                >
                                    Edit
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editingQRCode && (
                <div className="mt-4 p-4 bg-gray-100 border rounded">
                    <h3 className="text-lg font-semibold mb-2">Edit QR Code</h3>
                    {!lifetimeValidity && (
                        <>
                            <div className="mb-2">
                                <label className="block mb-1">Start Date:</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block mb-1">End Date:</label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    className="p-2 border rounded w-full"
                                />
                            </div>
                        </>
                    )}
                    <div className="mb-4">
                        <input
                            type="checkbox"
                            checked={lifetimeValidity}
                            onChange={(e) => handleLifetimeValidityChange(e.target.checked)}
                            className="mr-2 leading-tight"
                        />
                        <span className="text-gray-700 text-sm font-semibold mb-2">Check for Permanent Visitor</span>
                    </div>
                    <button
                        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                        onClick={handleSaveClick}
                    >
                        Save
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        onClick={() => setEditingQRCode(null)}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
};

export default VisitorQRCodesPage;
