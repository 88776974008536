// src/pages/PasswordResetPage.js
import React from 'react';
import PasswordReset from '../components/forms/PasswordReset';

const PasswordResetPage = () => {
  return (
    <div className="container mx-auto mt-2">
      <PasswordReset />
    </div>
  );
};

export default PasswordResetPage;
